<!-- 调查问卷 -->
<template>
  <div class="question-list">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '问卷调查',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="handleLoad"
      v-if="list && list.length"
    >
      <van-cell
        v-for="(item, i) in list"
        :key="i"
        :title="item.questionName"
        @click="handleClick(item)"
        is-link
        >{{ item.createTime }}</van-cell
      >
    </van-list>
    <van-empty v-else description="暂无数据" />
  </div>
</template>

<script>
import { getPageMyPart } from "@/api/question";
import TopWrapper from "@/components/topWrapper/index.vue";
export default {
  data() {
    return {
      params: {
        pageNum: 1,
        pageSize: 10,
        questionName: "",
      },
      list: null,
      loading: false,
      finished: false,
      total: 0,
    };
  },

  components: { TopWrapper },

  computed: {},

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      getPageMyPart(this.params)
        .then((res) => {
          if (this.params.pageNum == 1) {
            this.list = res.data.records;
          } else {
            this.list = this.list.concat(res.data.records);
          }
          this.params.pageNum++;
          if (this.params.pageNum >= res.data.pages) {
            this.finished = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleLoad() {
      this.getList();
    },
    handleClick(item) {
      this.$router.push({
        path: `/question/${item.questionCode}`,
        query: {
          fillCode: item.fillCode,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.question-list {
  min-height: 100%;
  background: #fafafa;
  padding-bottom: 0.2rem;
  ::v-deep .van-cell__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  ::v-deep .van-cell__value{
    font-size: 0.28rem;
  }
}
</style>
